

















































































import { Component, Vue } from "vue-property-decorator";
import { getCharacter } from "@/utils/common.util";
import BottomButton from "@/views/optional/components/BottomButton.vue";
import { Popup, Toast } from "vant";
import MySupplyCard from "@/views/optional/components/MySupplyCard.vue";
import SearchApi from "@/api/search.api";
import Empty from "@/components/EmptyBox.vue"
@Component({
  components: {
    BottomButton,
    Popup,
    MySupplyCard,
    Empty
  },
})
export default class PrizeDetail extends Vue {
  items: any = {};
  show = false;
  wideHigh = 0;
  supplyList: any = [];
  fillStatus = false;
  async created() {
    this.items = await SearchApi.getPrizeDetail(this.$route.query.id);
    const { clientWidth, offsetWidth } = document.body;
    const body_w = clientWidth || offsetWidth;
    this.wideHigh = body_w / 3 - 28;
    this.supplyList = await SearchApi.getSupplyHave(this.items.need_character);
}
  get currentCharacter() {
    const text = getCharacter(this.items.character, "", true);
    const icon =
      this.items.character < 5
        ? ""
        : `__character_peak_${this.items.character - 4}_icon`;
    return { text, icon };
  }
  get changebackground() {
    if (this.select.length == this.items.need_num) {
      return true;
    } else {
      return false;
    }
  }
  get possibleStyle() {
    if (!this.items) return {};
    return {
      background: `var(--card-color-${this.items.character})`,
    };
  }
  get currentNeedCharacter() {
    const text = getCharacter(this.items.need_character, "", true);
    const icon =
      this.items.character < 5
        ? ""
        : `__character_peak_${this.items.need_character - 4}_icon`;
    return { text, icon };
  }

  get possibleNeedStyle() {
    if (!this.items) return {};
    return {
      background: `var(--card-color-${this.items.need_character})`,
    };
  }
  get numberStyle() {
    if (!this.items) return {};
    return {
      color: `var(--card-color-${this.items.character})`,
    };
  }
  select: any = [];
  checksupply(index: any) {
    if (
      this.select.length >= this.items.need_num &&
      !this.select.includes(index)
    )
      return;
    if (this.select.includes(index)) {
      this.select = this.select.filter((idx: any) => {
        return idx != index;
      });
    } else {
      this.select.push(index);
    }
  }
  toChange() {
    this.show = true;
  }
  async toCombine() {
    if (this.select.length == this.items.need_num) {
      let voucher_ids: any[] = [];
      let entity_ids: any[] = [];
      this.select.forEach((item: any) => {
        if (this.supplyList[item].supply_type == 1) {
          voucher_ids.push(this.supplyList[item].id);
        } else {
          entity_ids.push(this.supplyList[item].id);
        }
      });
      const toast = Toast.loading({ message: "正在合成...", duration: 0 });
      try {
        let o = await SearchApi.putCombinePrize(
          this.$route.query.id,
          voucher_ids,
          entity_ids
        );
        await SearchApi.verifyToken(o.token);
        Toast('合成成功!请前往我的补给查看', { duration: 5000 });
        this.$router.back();
      } catch (e) {
        toast.close();
      }
    }
  }
  oneBuy() {
    if (this.select.length == this.items.need_num) {
      this.select = [];
    } else {
      let q = 0;
      for (let i = this.select.length; i < this.items.need_num; i++) {
        for (let q = 0; q < this.supplyList.length; q++) {
          if (
            !this.select.includes(q) &&
            this.select.length < this.items.need_num
          ) {
            this.select.push(q);
          }
        }
      }
    }
  }
}
